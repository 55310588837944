<script setup>
import { ref, inject} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import screenSplitLargeLeft from "@/components/ui/baseComponents/screenSplitLargeLeft.vue";
import SchedulingUserData from "@/components/ui/MA/appraisals/SchedulingUserData.vue";
import ScheduleingInformation from "@/components/ui/MA/appraisals/schedulingInformation.vue";
import Swal from "sweetalert2";

const store = useStore();
const router = useRouter();
const toast = inject("toast");

const inductionPoliciessSelected = ref([]);

const currentUserData = ref(null);

const submit = async (data) => {
  if(inductionPoliciessSelected.value.length < 1 || inductionPoliciessSelected.value[0] === undefined){
    toast.error("Selected role does not have a checklist.");
    return;
  }
  try {
    const obj = {
      practice: store.getters["MAgeneralModule/getCurrentPractice"],
      user: currentUserData.value.id,
      department: currentUserData.value.department_id,
      ...data,
    };
    obj.checklists = inductionPoliciessSelected.value.map((item) => {
      return {
        induction_checklist_id: item,
        is_complete: 0,
      };
    });

    const result = await store.dispatch(
      "MAgeneralModule/saveInductionSchedule",
      obj
    );
    if (result) {
      Swal.fire({
        title: "Done",
        text: "induction scheduled successfully",
        icon: "success",
      });
      router.push({
        name: "ma-staff-induction",
      });
    }
  } catch (error) {
    console.log(error);
  }
};
const dataObject = {
  module: "MAgeneralModule",
  action: "fetchInductionPolicies",
  getter: "getInductionPolicies",
  appendData: true,
};
const reRoute = () => {
  router.back();
};
</script>
<template>
  <screen-split-large-left>
    <template v-slot:leftSide>
      <div
        class="flex items-center w-full text-teal mt-2 ml-5 cursor-pointer w-24"
        @click="reRoute"
      >
        <span class="material-icons-outlined"> arrow_back </span>
        go Back
      </div>
      <SchedulingUserData
        v-model:checklists="inductionPoliciessSelected"
        v-model:user="currentUserData"
        :moduleConfig="dataObject"
        :mainTitle="'Schedule Induction'"
      />
    </template>
    <template v-slot:rightSide
      ><ScheduleingInformation
        @submitForm="submit"
        :title="'Schedule Induction'"
        :secondaryTitle="'induction'"
      >
      </ScheduleingInformation>
    </template>
  </screen-split-large-left>
</template>
